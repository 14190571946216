<template>
  <div>
    <p class="display-1 mt-2 mb-5 text-uppercase">DANH SÁCH ORDER</p>
    <v-card
      min-height="400"
      :ripple="false"
      width="100%"
      rounded="lg"
      class="pa-8"
    >
      <v-row no-gutters>
        <v-spacer />
        <v-btn
          color="primary"
          @click="dialog.orderCreate = true"
        >
          <v-icon left>mdi-plus</v-icon>
          Tạo order
        </v-btn>
      </v-row>
      <div class="">
        <v-card
          class="mt-6"
          elevation="0"
          rounded="xl"
        >
          <v-card-text>
            <v-row no-gutters>
              <v-col
                cols="2"
                class="font-weight-bold"
              >
                Mã Order
              </v-col>
              <v-col
                cols="2"
                class="font-weight-bold"
              >
                Ngày tạo
              </v-col>
              <v-col
                cols="2"
                class="font-weight-bold"
              >
                Cảng
              </v-col>
              <v-col
                cols="3"
                class="font-weight-bold"
              >
                Dịch vụ
              </v-col>
              <v-col
                cols="2"
                class="font-weight-bold"
              >
                Trạng thái
              </v-col>
              <v-col
                cols="1"
                class="font-weight-bold"
              >
                Thao tác
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          class="mt-2"
          style="cursor: pointer"
          color="background"
          elevation="0"
          rounded="xl"
          :ripple="false"
          v-for="item in orderList"
          :key="item.id"
          @click="onViewOrder(item)"
          :to="{ path: '/app/port/my-order/' + item.id }"
        >
          <v-card-text>
            <v-row
              no-gutters
              class="align-center"
            >
              <v-col
                cols="2"
                class="font-weight-bold primary--text text-truncate"
              >
                {{ item.code }}
              </v-col>
              <v-col
                cols="2"
                class="text-truncate"
              >
                {{ formatDate(item.createdAt) }}
              </v-col>
              <v-col
                cols="2"
                class="text-truncate"
              >
                {{ item.port.name }}
              </v-col>
              <v-col
                cols="3"
                class="text-truncate"
              >
                {{ item.customerService.name }}
              </v-col>
              <v-col
                cols="2"
                class="text-truncate"
              >
                {{ formatStatus(item.status) }}
              </v-col>
              <v-col
                cols="1"
                class="text-truncate"
              >
                <v-btn
                  @click.stop.prevent="onClickDeleteOrder(item)"
                  :disabled="!checkCanDeleteOrder(item)"
                  icon
                  color="error"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <template v-if="isLoading">
          <v-card
            class="mt-2"
            style="cursor: pointer"
            color="background"
            elevation="0"
            rounded="xl"
            v-for="(item, i) in 5"
            :key="i"
          >
            <v-card-text>
              <v-skeleton-loader
                :boilerplate="false"
                type="text@2"
              />
            </v-card-text>
          </v-card>
        </template>
        <NoData v-if="!isLoading && orderList.length === 0" />
      </div>
    </v-card>

    <v-dialog
      v-model="dialog.orderCreate"
      max-width="550px"
    >
      <OrderCreate
        v-if="dialog.orderCreate"
        @close-dialog="dialog.orderCreate = false"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.orderDelete"
      max-width="550px"
    >
      <OrderDelete
        v-if="dialog.orderDelete"
        :item="selectedOrder"
        @close-dialog="dialog.orderDelete = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapMutations, mapState } from 'vuex';
import OrderCreate from './OrderCreate.vue';
import { OrderStatus } from '@/constants/status.constant';
import OrderDelete from './OrderDelete.vue';
import NoData from '@/components/shared/local/base/NoData.vue';
export default {
  components: { OrderCreate, OrderDelete, NoData },
  data: () => ({
    isLoading: false,
    dialog: {
      orderCreate: false,
      orderDelete: false,
    },
    selectedOrder: null,
  }),
  computed: {
    ...mapState('vpl/order', ['orderList']),
  },
  async created() {
    try {
      this.isLoading = true;
      console.log('[LOG] :  this.isLoading', this.isLoading);
      await this.fetchOrderList();
    } finally {
      this.isLoading = false;
      console.log('[LOG] :  this.isLoading', this.isLoading);
    }
  },
  destroyed() {
    this.RESET(['orderList']);
  },
  methods: {
    ...mapMutations('vpl/order', ['RESET']),
    ...mapActions('vpl/order', ['fetchOrderList']),
    formatDate(value) {
      return dayjs(value).format('DD.MM.YYYY');
    },
    formatStatus(value) {
      if (value === 'DRAFT') {
        return 'Khởi tạo';
      }
      if (value === 'PENDING') {
        return 'Chờ duyệt';
      }
      if (value === 'APPROVED') {
        return 'Đã được duyệt';
      }
      if (value === 'CANCELED') {
        return 'Đã hủy';
      }
      return value;
    },
    checkCanDeleteOrder(item) {
      if (item.status === OrderStatus.DRAFT) {
        return true;
      }
      return false;
    },
    onClickDeleteOrder(item) {
      this.selectedOrder = item;
      this.dialog.orderDelete = true;
    },
    onViewOrder(item) {
      console.log('[LOG] : item', item);
    },
  },
};
</script>

<style></style>
